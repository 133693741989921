import {
  ensureSessionIdAccessCookie,
  getCSRFTokenCookie,
} from '../session/session';
import { isIwocaDE } from '../countryHelper';
import { pageTagsDE } from './pageTagDE';
import { pageTagsUK } from './pageTagUK';

export type PageURL = string;

export type PageTag = string;

export type PageTags = {
  url: Record<PageURL, PageTag>;
  param?: Record<PageURL, PageTag>;
};

export function addPageTag() {
  if (typeof window === 'undefined') {
    return;
  }

  const pageTags = isIwocaDE() ? pageTagsDE : pageTagsUK;
  const pageTag = getPageTag(pageTags);

  if (!pageTag) {
    return;
  }

  // Log to console if in development mode
  if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
    console.log('Tagging page:', pageTag);
    return;
  }

  sendPageTagToApi(pageTag)
    .then()
    .catch((error) => {
      typeof window !== 'undefined' &&
        console.warn('Something went wrong sending page tags');
    });
}

export async function sendPageTagToApi(pageTag: PageTag) {
  let headers: Record<string, string> = {
    'Content-Type': 'application/json;charset=UTF-8',
  };

  const sessionId = ensureSessionIdAccessCookie();
  if (sessionId) {
    headers.session_id = sessionId;
  }

  const csrftoken = getCSRFTokenCookie();
  if (csrftoken) {
    headers['x-csrftoken'] = csrftoken;
  }

  return fetch('/api/referers/add_page_tag/', {
    method: 'POST',
    headers,
    body: JSON.stringify({
      tag: pageTag,
    }),
  });
}

export function getPageTag(pageTags: PageTags): string | undefined {
  const queryParameters = new URLSearchParams(window.location.search);
  const pageTagParam = queryParameters.get('ptag');
  let paramPageTag = null;

  if (pageTagParam && pageTags.param) {
    paramPageTag = pageTags.param[pageTagParam];
  }

  const path = `${window.location.pathname}${window.location.search}`;
  const pathWithoutSearch = normalisePath(window.location.pathname);
  const normalisedPath = normalisePath(path);
  const pagePageTag = pageTags['url'][normalisedPath];
  const pageWithoutSearchPageTag = pageTags['url'][pathWithoutSearch];

  if (paramPageTag) {
    return paramPageTag;
  }

  if (pagePageTag) {
    return pagePageTag;
  }

  if (pageWithoutSearchPageTag) {
    return pageWithoutSearchPageTag;
  }
}

export function normalisePath(path: string) {
  if (path === '/') {
    return path;
  }

  if (/.+\?/.test(path)) {
    return path;
  }

  return `${path.replace(/\/$/, '')}/`;
}
