import { ErrorMessage } from 'formik';
import styles from './FormErrorMessage.module.css';

const FormErrorMessage = ({ name }: { name: string }) => (
  <ErrorMessage name={name}>
    {(msg) => (
      <div data-testid={`${name}-form-error`} className={styles.inputFeedback}>
        <ErrorIcon />
        <p dangerouslySetInnerHTML={{ __html: msg }} />
      </div>
    )}
  </ErrorMessage>
);

const ErrorIcon = ({ color = '#960a03' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="error-icon"
    >
      <circle cx="8" cy="8" r="8" fill="white" />
      <path
        d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 12C7.4 12 7 11.6 7 11C7 10.4 7.4 10 8 10C8.6 10 9 10.4 9 11C9 11.6 8.6 12 8 12ZM9 9H7V4H9V9Z"
        fill={color}
      />
    </svg>
  );
};

export default FormErrorMessage;
