import { getCookie } from './cookieHelper';

export const UK_LOCALE = 'en';
export const DE_LOCALE = 'de';
export type SupportedLocales = typeof UK_LOCALE | typeof DE_LOCALE;
export const isDELocale = (locale: SupportedLocales) => locale === DE_LOCALE;

export const isIwocaDE = () => {
  const host = window.location.host;
  return (
    (host && host.indexOf('.de') !== -1) ||
    isDELocale(getCookie('locale') as SupportedLocales)
  );
};

export const getCurrency = (locale: SupportedLocales) =>
  isDELocale(locale) ? 'EUR' : 'GBP';
export const getCurrencySymbol = (locale: SupportedLocales) =>
  isDELocale(locale) ? '€' : '£';
export const getCurrencyThousandSeperator = (locale: SupportedLocales) =>
  isDELocale(locale) ? '.' : ',';
export const getCurrencyDecimalSeperator = (locale: SupportedLocales) =>
  isDELocale(locale) ? ',' : '.';
