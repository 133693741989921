import { useLingui } from '@lingui/react';
import { Field, FieldProps } from 'formik';
import { isDELocale, SupportedLocales } from '../countryHelper';
import styles from './MarketingCheckbox.module.css';

const MarketingCheckbox = () => {
  const lingui = useLingui();
  if (isDELocale(lingui.i18n.locale as SupportedLocales)) {
    return <MarketingCheckboxDE />;
  }

  return (
    <div className={styles.marketingOption}>
      <Field name="email_marketing_opt_in">
        {({ field }: FieldProps) => (
          <input
            {...field}
            id="email_marketing_opt_in"
            type="checkbox"
            checked={field.value}
          />
        )}
      </Field>
      <label htmlFor="email_marketing_opt_in">
        <strong>Sign up for updates</strong> – we’ll email offers and stories
        from other small businesses.
      </label>
    </div>
  );
};

const MarketingCheckboxDE = () => {
  return (
    <div className={styles.marketingOptionDE}>
      <p>
        Klicken Sie hier, um über Neuigkeiten von iwoca auf dem Laufenden
        gehalten zu werden.
      </p>
      <Field name="email_marketing_opt_in">
        {({ field }: FieldProps) => (
          <input
            {...field}
            id="email_marketing_opt_in"
            type="checkbox"
            checked={field.value}
          />
        )}
      </Field>
      <label htmlFor="email_marketing_opt_in">Ja (jederzeit widerrufbar)</label>
    </div>
  );
};

export default MarketingCheckbox;
