import { ensureSessionIdAccessCookie } from '../session/session';

export const logSessionIdAccess = () => {
  let sessionId = ensureSessionIdAccessCookie();

  fetch('/api/access_log/', {
    method: 'POST',
    headers: { session_id: sessionId },
    body: JSON.stringify({
      url: window.location.pathname,
      params: window.location.search,
      referrer: document.referrer,
    }),
  })
    .then()
    .catch((error) => {
      typeof window !== 'undefined' &&
        console.warn('iwoca session id is not implemented yet');
    });
};
