export function postToDataLayer(data: any) {
  if (!window.dataLayer) {
    return;
  }

  window.dataLayer.push(data);
}

export function logErrorToDataLayer(errorType: string) {
  postEventToDataLayer('error', {
    errorData: {
      errorType,
      url: typeof window !== 'undefined' && window.location.href,
    },
  });
}

export function postEventToDataLayer(eventName: string, eventData: object) {
  if (!eventName || !eventData) return;

  return {
    event: eventName,
    ...eventData,
  };
}
