import Cookies from 'js-cookie';
import { v4 } from 'uuid';

export function getSessionIdAccessCookie() {
  return Cookies.get('sessionid_access');
}

export function getCSRFTokenCookie() {
  return Cookies.get('csrftoken');
}

export function setSessionIdAccessCookie() {
  const sessionId = v4();
  Cookies.set('sessionid_access', sessionId, {
    expires: 60,
    domain: window.location.hostname.replace('www.', ''),
  });
}

export const ensureSessionIdAccessCookie = (): string => {
  let sessionId: any = getSessionIdAccessCookie();

  if (!sessionId) {
    setSessionIdAccessCookie();
    sessionId = getSessionIdAccessCookie();
  }

  return sessionId;
};
