import React from 'react';
import styles from './NoAmountCheckbox.module.css';
import { Field, FieldProps } from 'formik';
import { CheckBox } from '@iwoca/orion';

const NoAmountCheckbox = () => {
  return (
    <div className={styles.noAmountCheckBox}>
      <Field name="no_amount_checkbox">
        {({ field, form }: FieldProps) => (
          <CheckBox
            {...field}
            id="no_amount_checkbox"
            type="checkbox"
            checked={field.value}
            label="I’m not sure right now"
          />
        )}
      </Field>
    </div>
  );
};

export default NoAmountCheckbox;
