import React from 'react';
import ReactDOM from 'react-dom';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { en, de } from 'make-plural/plurals';
import { messages as ukMessages } from './locales/en/messages';
import { messages as deMessages } from './locales/de/messages';
import { DE_LOCALE, isIwocaDE, UK_LOCALE } from './countryHelper';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { logSessionIdAccess } from './referral/referral';
import { addPageTag } from './pageTag/pageTag';

// Log the referrer using the access_log api
logSessionIdAccess();

// Add the page tag
addPageTag();

i18n.load(UK_LOCALE, ukMessages);
i18n.load(DE_LOCALE, deMessages);
i18n.loadLocaleData({
  en: { plurals: en },
  de: { plurals: de },
});
i18n.activate(isIwocaDE() ? DE_LOCALE : UK_LOCALE);

const rootElement = document.getElementById('root');

if (rootElement) {
  ReactDOM.render(
    <React.StrictMode>
      <I18nProvider i18n={i18n}>
        <App appVersion={rootElement.dataset.version!} />
      </I18nProvider>
    </React.StrictMode>,
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
