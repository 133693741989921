import { PageTags } from './pageTag';

export const pageTagsDE: PageTags = {
  url: {
    '/kreditzugang/': 'kredit_zugang',
    '/qonto/': 'qonto',
    '/ebay/': 'ebay_pilot',
    '/fyrst/': 'fyrst',
    '/airbank/': 'airbank',
    '/axa/': 'axa',
  },
  param: {
    dfkp: 'dfkp',
  },
};
