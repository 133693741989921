import { Field, FieldProps, FormikHelpers } from 'formik';
import NumberFormat from 'react-number-format';
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';

import * as formHelpers from './form';
import * as products from '../products';

import styles from './FormFields.module.css';
import * as countryHelpers from '../countryHelper';
import FormErrorMessage from './FormErrorMessage';
import { useLingui } from '@lingui/react';
import NoAmountCheckbox from './NoAmountCheckbox/NoAmountCheckbox';
import { Input } from '@iwoca/orion';

type AmountFieldProps = products.ProductConfig & {
  validateField: FormikHelpers<formHelpers.FormValues>['validateField'];
  disabled: boolean | undefined;
  isDE: boolean;
  appVersion: string | undefined;
};
const AmountField = ({
  minAmount,
  maxAmount,
  validateField,
  disabled,
  isDE,
  appVersion,
}: AmountFieldProps) => {
  const lingui = useLingui();
  const locale = lingui.i18n.locale as countryHelpers.SupportedLocales;
  const getPlaceholder = ({ minAmount, maxAmount }: products.ProductConfig) => {
    if (minAmount > 0) {
      return t`Between ${i18n.number(minAmount, {
        style: 'currency',
        currency: countryHelpers.getCurrency(locale),
        maximumFractionDigits: 0,
      })} and ${i18n.number(maxAmount, {
        style: 'currency',
        currency: countryHelpers.getCurrency(locale),
        maximumFractionDigits: 0,
      })}`;
    }

    return t`Any amount, up to ${i18n.number(maxAmount, {
      style: 'currency',
      currency: countryHelpers.getCurrency(locale),
      maximumFractionDigits: 0,
    })}`;
  };

  return (
    <div className={styles.formGroup}>
      <label htmlFor="requestedAmount">
        <Trans>How much would you like?</Trans>
      </label>
      <div className={styles.input}>
        <Field name="requestedAmount">
          {({ form, field }: FieldProps<any, formHelpers.FormValues>) => (
            <NumberFormat
              disabled={disabled}
              data-testid="amount-input"
              id="requestedAmount"
              inputMode="numeric"
              type="tel"
              value={form.values.no_amount_checkbox ? undefined : field.value}
              placeholder={getPlaceholder({ minAmount, maxAmount })}
              className={
                (form.errors.requestedAmount || '') &&
                (form.touched.requestedAmount || '') &&
                styles.inputError
              }
              thousandSeparator={countryHelpers.getCurrencyThousandSeperator(
                locale
              )}
              decimalSeparator={countryHelpers.getCurrencyDecimalSeperator(
                locale
              )}
              customInput={Input}
              decimalScale={0}
              prefix={countryHelpers.getCurrencySymbol(locale)}
              allowNegative={false}
              isAllowed={({ formattedValue, floatValue }) => {
                if (formattedValue === '') return true;

                return (floatValue || 0) <= maxAmount;
              }}
              onValueChange={(values) => {
                form.setFieldValue(
                  'requestedAmount',
                  values.floatValue ? values.floatValue : null
                );
                validateField('requestedAmount');
              }}
            />
          )}
        </Field>
        <FormErrorMessage name="requestedAmount" />
        {!isDE && appVersion === 'no-amount-checkbox' && (
          <div className={styles.noAmountCheckbox}>
            <NoAmountCheckbox />
          </div>
        )}
      </div>
    </div>
  );
};

export default AmountField;
