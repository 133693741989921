import { Field, FieldProps } from 'formik';
import * as formHelpers from './form';
import styles from './FormFields.module.css';
import FormErrorMessage from './FormErrorMessage';
import { Select } from '@iwoca/orion';

export const LengthOfLoanField = ({
  disabled,
}: {
  disabled: boolean | undefined;
}) => {
  return (
    <div className={styles.formGroup}>
      {/* TODO: Confirm default text */}
      <label htmlFor="lengthOfLoan">How long do you need the funds for?</label>
      <div className={styles.input}>
        <Field name="lengthOfLoan">
          {({ form, field }: FieldProps<any, formHelpers.FormValues>) => (
            <Select
              {...field}
              disabled={disabled}
              id="lengthOfLoan"
              onChange={(e) => {
                form.handleChange(e);
              }}
              className={styles.select}
              placeholder="Select an option..."
            >
              <option value="few_months">A few months</option>
              <option value="one_to_two_years">Next 1-2 years</option>
              <option value="three_plus_years">Next 3+ years</option>
              <option value="ongoing">I’d like to dip in and out</option>
              <option value="unsure">I'm unsure at the moment</option>
            </Select>
          )}
        </Field>
        <FormErrorMessage name="lengthOfLoan" />
      </div>
    </div>
  );
};
