import { Field, FieldProps } from 'formik';
import { FormValues } from './form';
import FormErrorMessage from './FormErrorMessage';
import styles from './FormFields.module.css';
import { Select } from '@iwoca/orion';

export const PurposeField = () => {
  return (
    <div className={styles.formGroup}>
      <label htmlFor="purpose">How will you use the funds?</label>
      <div className={styles.input}>
        <Field name="purpose">
          {({ form, field }: FieldProps<FormValues['purpose'], FormValues>) => (
            <Select
              {...field}
              id="purpose"
              placeholder="Select an option"
              onChange={(e) => {
                form.handleChange(e);
              }}
              className={`
                ${styles.select}
                ${
                  form.errors.purpose &&
                  form.touched.purpose &&
                  styles.inputError
                }
              `}
            >
              <option value="stock_purchase">Purchasing stock</option>
              <option value="pay_staff">Paying wages</option>
              <option value="pay_bill">Paying a bill</option>
              <option value="refinancing_debt">Refinancing debt</option>
              <option value="growing_business">Growing my business</option>
              <option value="supporting_cashflow">Supporting cash flow</option>
              <option value="other">Other purposes</option>
              <option value="no_specific_need">No specific need</option>
            </Select>
          )}
        </Field>
        <FormErrorMessage name="purpose" />
      </div>
    </div>
  );
};
