/*eslint-disable*/ module.exports = {
  messages: {
    'Any amount, up to {0}': ['Any amount, up to ', ['0']],
    'Apply for a {productName}': ['Apply for a ', ['productName']],
    'Apply now': 'Apply now',
    'Between {0} and {1}': ['Between ', ['0'], ' and ', ['1']],
    'Email already taken - <a href="/login" data-ga-id="step0_email_already_exists__login">log in</a>':
      'Email already taken - <a href="/login" data-ga-id="step0_email_already_exists__login">log in</a>',
    'Enter your business email': 'Enter your business email',
    'How much would you like?': 'How much would you like?',
    'Please enter a valid email address': 'Please enter a valid email address',
    Submitting: 'Submitting',
    'What is your business email address?':
      'What is your business email address?',
  },
};
