import { PageTags } from './pageTag';

export const pageTagsUK: PageTags = {
  url: {
    '/lightning-loan/': 'lightning_loan',
    '/Funding-Circle-Flexiloan/': 'funding_circle',
    '/finance-options/': 'finance_options',
    '/finance-options/business-van-finance': 'business_van_finance',
    '/finance-options/cash-flow-loans': 'cash_flow_loans',
    '/finance-options/commercial-finance': 'commercial_finance',
    '/finance-options/ecommerce-funding': 'ecommerce_funding',
    '/finance-options/fast-business-loans': 'fast_business_loans',
    '/finance-options/instant-business-loans': 'instant_business_loans',
    '/finance-options/interest-free-business-loans':
      'interest_free_business_loans',
    '/finance-options/micro-business-loans': 'micro_business_loans',
    '/finance-options/online-business-loans': 'online_business_loans',
    '/partners': 'partners',
    '/finance-options/pay-as-you-grow-loan': 'pay_as_you_grow_loan',
    '/finance-options/revenue-based-finance': 'revenue_based_finance',
    '/finance-options/revolving-credit-facility': 'revolving_credit_facility',
    '/finance-options/start-up-business-loans': 'start_up_business_loans',
    '/finance-options/working-capital-loans': 'working_capital_loans',
  },
};
